<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Advertise
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Raise your profile today</h2>
          <p>
            The energy transition is fast-moving – and evolving rapidly. Decision-makers in the wind, solar and other renewables sectors, along with policy-makers and financiers, need to know what’s happening and why it matters. They turn to Recharge for quality insight and breaking industry news – Recharge has over 500,000 monthly page impressions and 250,000 unique users.
          </p>
          <p>
            <strong>With opportunities including daily newsletters, sponsored content and digital and print banners, there are more ways than ever to reach our high-value readership.</strong>
          </p>
          <p>
            Reach the key players in renewable energy. Advertise with Recharge.
          </p>
          <p>
            Get the full picture by downloading a media pack below:<br><br>
          </p>
          <p>
            <strong>Media Kits 2020</strong>
          </p>
          <p>
            <strong>
              <a href="/resources/gfx/recharge/Recharge_Media_Guide2020-EUR.pdf">
                Media kit for Europe (EUR)
              </a>
            </strong><br>
            <strong>
              <a href="/resources/gfx/recharge/Recharge_Media_Guide2020-GBP.pdf">
                Media kit for UK (GBP)
              </a>
            </strong><br>
            <strong>
              <a href="/resources/gfx/recharge/Recharge_Media_Guide2020-NOK.pdf">
                Media kit for Norway (NOK)
              </a>
            </strong><br>
            <strong>
              <a href="/resources/gfx/recharge/Recharge_Media_Guide2020-USD.pdf">
                Media kit for Rest of the world (USD)
              </a>
            </strong><br>
            <strong>
              <a href="/resources/gfx/recharge/Recharge_Media_Guide_Calendar-2020.pdf">
                Events Calendar 2020
              </a>
            </strong>
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'advertise-page',
  extends: Page
};
</script>
